/* Master Styles */

/* ##############################################

Estilo que venían por defecto

############################################## */
h1 {
    color: #369;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 250%;
}
h2,
h3 {
    color: #444;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}
body {
    margin: 2em;
}

/* ##############################################

Código loader Spinner

############################################## */

.loader {
    margin: 60px auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(169, 169, 169, 0.2);
    border-right: 1.1em solid rgba(169, 169, 169, 0.2);
    border-bottom: 1.1em solid rgba(169, 169, 169, 0.2);
    border-left: 1.1em solid #a9a9a9;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes load7 {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes load7 {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.kawaii-loader {
    margin: 60px auto;
    position: relative;
    background-size: 100% 100%;
    height: 140px;
    width: 140px;
}
.kawaii-loader div {
    background-size: 100% 100%;
    height: 140px;
    width: 140px;
    position: fixed;
}
.kawaii-loader div.c1 {
    background-image: url("../img/kawaiinebu_c1.png");
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 3.1s infinite linear;
    animation: load8 3.1s infinite linear;
}
.kawaii-loader div.c2 {
    background-image: url("../img/kawaiinebu_c2.png");
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 2.1s infinite linear;
    animation: load8 2.1s infinite linear;
}
.kawaii-loader div.c3 {
    background-image: url("../img/kawaiinebu_c3.png");
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load7 5.1s infinite linear;
    animation: load7 5.1s infinite linear;
}
.kawaii-loader div.c4 {
    background-image: url("../img/kawaiinebu_0.png");
}

.modal {
    overflow: auto !important;
}
